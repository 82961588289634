import React, { FC, createContext, useContext, useMemo } from "react";

type CanaryContextType = {
  isCanary: boolean;
};

const CanaryContext = createContext<CanaryContextType | undefined>(undefined);

export type CanaryProviderProps = {
  isCanaryModeEnabled: boolean;
};

export const CanaryProvider: FC<CanaryProviderProps> = ({
  children,
  isCanaryModeEnabled,
}) => {
  const value = useMemo(
    () => ({
      isCanary: isCanaryModeEnabled,
    }),
    [isCanaryModeEnabled],
  );

  return (
    <CanaryContext.Provider value={value}>{children}</CanaryContext.Provider>
  );
};

CanaryProvider.displayName = "CanaryProvider";

export function useCanary(): CanaryContextType {
  const context = useContext(CanaryContext);

  if (typeof context === "undefined") {
    throw new Error(
      `'useCanary()' must be used within a ${CanaryProvider.displayName}`,
    );
  }

  return context;
}
