import { Aboyeur } from "@ifood/aboyeur";
import { getMerchantIdFromUrl } from "@whitelabel-webapp/shared/url-utils";

export const appRevision = 1;

export const appAboyeur = new Aboyeur("app", {
  pages: {
    view: (metadata: {
      page: string;
      page_url: string;
      merchant_id: string;
      coupon?: string;
    }) => ({
      label: "pageview",
      metadata,
    }),
  },
  redirect: {
    siteMercado: () => ({
      label: "redirect_site_mercado",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
  },
  catch: {
    onError: (error: Error) => ({
      label: "error",
      metadata: { error: error.message, merchant_id: getMerchantIdFromUrl() },
    }),
  },
  pwa: {
    viewInstallationModal: () => ({
      label: "installation_modal_view",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    installClick: () => ({
      label: "installation_modal_install_click",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    closeClick: () => ({
      label: "installation_modal_close_click",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    installAccepted: () => ({
      label: "installation_modal_install_accepted",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    installDeclined: () => ({
      label: "installation_modal_install_declined",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
  },
  externalIntegration: {
    clickToWhatsapp: () => ({
      label: "click_to_whatsapp",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
  },
  experiments: {
    /**
     * @when The user clicks to view the catalog
     **/
    enter: (name: string, value: string | number | boolean) => ({
      label: "enter_to_experiment",
      metadata: { value, name, merchant_id: getMerchantIdFromUrl() },
    }),
  },
}).withMetadata({ revision: appRevision });
