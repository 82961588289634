import { Aboyeur, Metadata } from "@ifood/aboyeur";
import { FasterMetadata, FasterPlugin } from "@ifood/aboyeur-plugin-faster";
import {
  GoogleAnalyticsMetadata,
  GoogleAnalyticsPlugin,
} from "@ifood/aboyeur-plugin-ga";
import {
  SentryBrowserMetadata,
  SentryBrowserPlugin,
} from "@ifood/aboyeur-plugin-sentry-browser";
import * as Sentry from "@sentry/react";

import pkg from "../../../../package.json";
import { ENVS } from "./envs";
import { getIsDevelopment, getIsTest } from "./utils";
import { CookiesStorage } from "@whitelabel-webapp/shared/models";

const PLUGINS: {
  [x: string]:
    | typeof GoogleAnalyticsPlugin
    | typeof FasterPlugin
    | typeof SentryBrowserPlugin;
} = {
  [FasterPlugin.displayName]: FasterPlugin,
  [SentryBrowserPlugin.displayName]: SentryBrowserPlugin,
};

type AboyeurMetadata = SentryBrowserMetadata &
  FasterMetadata &
  Partial<GoogleAnalyticsMetadata>;

type initializeProps = {
  googleAnalyticsId?: string;
  isTest?: boolean;
  sourceId?: string;
  merchantId?: string;
  merchantName?: string;
  chainId?: string;
};

export const ABOYEUR_METADATA: AboyeurMetadata = {
  faster: {
    version: pkg.version,
    apiKey: `${ENVS.FASTER_API_KEY}`,
    secret: `${ENVS.FASTER_SECRET}`,
  },
  sentryBrowser: {
    dsn: `${ENVS.SENTRY_DSN}`,
    release: `${ENVS.SENTRY_RELEASE}`,
    environment: getIsDevelopment() ? "local" : "production",
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [/^https:\/\/groceries-api\.ifood\.com\.br\//],
      }),
    ],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.1,
  },
};

const ABOYEUR_METADATA_MANDATORY_KEYS = ["faster", "sentryBrowser"] as const;

export function initializeAboyeur({
  isTest = false,
  googleAnalyticsId = "",
  sourceId = "",
  merchantId = "",
  merchantName = "",
  chainId = "",
}: initializeProps) {
  const dryRun = getIsDevelopment();

  if (googleAnalyticsId) {
    PLUGINS[GoogleAnalyticsPlugin.displayName] = GoogleAnalyticsPlugin;
    ABOYEUR_METADATA.googleAnalytics = {
      trackingIds: [googleAnalyticsId],
    };
  }

  ABOYEUR_METADATA.faster.metadata = {
    device: {
      sourceId,
      merchantId,
      merchantName,
      chainId,
    },
    session: {
      sourceId,
      merchantId,
      merchantName,
      chainId,
    },
  };

  clearFasterSession();
  Aboyeur.initialize(ABOYEUR_METADATA, PLUGINS, { isTest, dryRun });
}

export function isAboyeurMetadata(
  metadata: Metadata,
): metadata is AboyeurMetadata {
  if (!metadata || typeof metadata !== "object") {
    return false;
  }

  return ABOYEUR_METADATA_MANDATORY_KEYS.every((key) => key in metadata);
}

export function getFasterInstance() {
  const [fasterPlugin] = Aboyeur.plugins.filter(
    (plugin) => plugin.name === FasterPlugin.displayName,
  );

  const { instance } = fasterPlugin as FasterPlugin;
  if (!instance) {
    throw new Error("Faster Plugin must be initialized");
  }

  return instance;
}

const DEFAULT_DEVICE_ID = "6c458d7c-5aaf-4f25-896b-ab8ac4090b82";
export function getDeviceId(): string {
  if (getIsDevelopment() || getIsTest()) {
    return DEFAULT_DEVICE_ID;
  }

  const device = getFasterInstance().device.get();
  if (!device) return "";

  return device.id;
}

const DEFAULT_SESSION_ID = "fe65a788-33c4-4da5-83b9-8dca692f2dd3";
export function getSessionId(): string {
  if (getIsDevelopment() || getIsTest()) {
    return DEFAULT_SESSION_ID;
  }

  const session = getFasterInstance().session.get();
  if (!session) return "";

  return session.id;
}

export function clearFasterSession(): void {
  if (localStorage.getItem("fstr.session.clear")) return;
  localStorage.removeItem("fstr.session");
  CookiesStorage.delete("fstr.session", {
    path: "/",
    secure: true,
    httpOnly: false,
    sameSite: "strict",
  });
  localStorage.setItem("fstr.session.clear", "true");
}

export function mountAboyeurMetadata(
  metadata: AboyeurMetadata,
  accountId?: string,
): AboyeurMetadata {
  return {
    ...metadata,
    faster: {
      ...metadata.faster,
    },
    sentryBrowser: {
      ...metadata.sentryBrowser,
      user: {
        id: accountId,
      },
    },
  };
}

export function updateAboyeurMetadata(accountId?: string) {
  Aboyeur.updateMetadata((metadata) => {
    if (!isAboyeurMetadata(metadata)) return metadata;
    return mountAboyeurMetadata(metadata, accountId);
  });
}
