import { Aboyeur } from "@ifood/aboyeur";
import { getMerchantIdFromUrl } from "@whitelabel-webapp/shared/url-utils";

export const catalogRevision = 1;

export const catalogAboyeur = new Aboyeur("catalog", {
  catch: {
    /**
     * @when An unexpected error occurs
     */
    onError: (error: Error) => ({
      label: "error",
      metadata: { error: error.message, merchant_id: getMerchantIdFromUrl() },
    }),
  },
  showcase: {
    /**
     * @when The user click the showcase back or search button
     */
    back: () => ({
      label: "back",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    search: () => ({
      label: "search",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
  },
  catalog: {
    /**
     * @when The merchant does not have any available category or items
     */
    empty: () => ({
      label: "empty",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The user falls under the incentive to add the bag
     */
    triggerAddBag: () => ({
      label: "trigger_add_bag",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
  },
  category: {
    /**
     * @when The user click a catalog category
     */
    click: (name: string) => ({
      label: "click",
      metadata: { value: name, merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user scrolls on the highlighted category
     */
    scroll: (orientation: string, offset: number, totalSize: number) => ({
      label: "scroll",
      metadata: {
        orientation,
        offset: Number(offset),
        total_size: Number(totalSize),
        merchant_id: getMerchantIdFromUrl(),
      },
    }),
    /**
     * @when The user sees category aisle screen
     */
    viewAisleScreen: () => ({
      label: "view_aisle_screen",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
  },
  search: {
    /**
     * @when The user search a catalog items
     */
    searchCatalogItem: (query: string) => ({
      label: "search_catalog_item",
      metadata: { value: query, merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The user clicks in close search
     */
    closeSearch: () => ({
      label: "close_search",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The user sees search suggestions
     */
    viewSearchSuggestions: () => ({
      label: "view_search_suggestions",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
  },
  item: {
    /**
     * @when The user clicks in a list item on the catalog
     */
    click: (name: string, target: "default" | "horizontal", id: string) => ({
      label: "click",
      metadata: {
        target,
        item_id: id,
        item_name: name,
        merchant_id: getMerchantIdFromUrl(),
      },
    }),

    /**
     * @when A list item item is opened
     */
    openItemPage: (name: string, id: string) => ({
      label: "open_item_page",
      metadata: {
        item_id: id,
        item_name: name,
        merchant_id: getMerchantIdFromUrl(),
      },
    }),

    /**
     * @when The user share catalog item succesfully
     */
    share: (value: string) => ({
      label: "share",
      metadata: { value, merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user clicks to add the same item
     */
    sameItem: () => ({
      label: "add_sameItem",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The user clicks on open share
     */
    openItemPageShare: () => ({
      label: "open_details_share",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The user clicks on close share
     */
    closeDetailsShare: () => ({
      label: "close_details_share",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The user clicks in disabled add item button
     */
    disabledAddItemButtonClick: () => ({
      label: "disabled_add_item_button_click",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The user does not add a mandatory garnish item
     */
    viewEmptyGarnishItemAlert: () => ({
      label: "view_empty_garnish_item_alert",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
  },
  login: {
    click: () => ({
      label: "click",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    done: () => ({
      label: "done",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
  },
}).withMetadata({ revision: catalogRevision });
